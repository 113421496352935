import React, { Component,useState, useEffect } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiPercent, FiClock, FiTrendingUp, FiAward, FiTarget, FiPieChart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import SIH from "../../public/assets/images/SIH.webp";
import SIHLOGO from "../../public/assets/images/awardlogos/sih.png";
// import SIH1 from "../../public/assets/images/awardlogos/sih.png";
// import MyComponent from "../elements/divWidth";
import TNSILOGO from "../../public/assets/images/awardlogos/tnsi.png";
import { Carousel } from 'react-carousel-minimal';
import ImageSlider from "../components/slider/ImageSlider";
import ImageSlider1 from "../components/slider/ImageSlider1";
import { FaClock } from "react-icons/fa";
import TractionLayout from "../components/tractionLayout";

import SIH1 from "../../public/assets/images/awardphotos/SIH/SIH (1).png";
import SIH2 from "../../public/assets/images/awardphotos/SIH/SIH (2).png";
import SIH3 from "../../public/assets/images/awardphotos/SIH/SIH (3).png";
import SIH4 from "../../public/assets/images/awardphotos/SIH/SIH (4).png";
import SIH5 from "../../public/assets/images/awardphotos/SIH/SIH (5).png";
import SIH6 from "../../public/assets/images/awardphotos/SIH/SIH (6).png";
import SIH7 from "../../public/assets/images/awardphotos/SIH/SIH (7).png";

import TNSI1 from "../../public/assets/images/awardphotos/TNSI/TNSI (1).png";
import TNSI2 from "../../public/assets/images/awardphotos/TNSI/TNSI (2).jpg";
import TNSI3 from "../../public/assets/images/awardphotos/TNSI/TNSI (3).jpg";
import TNSI4 from "../../public/assets/images/awardphotos/TNSI/TNSI (4).jpg";
import TNSI5 from "../../public/assets/images/awardphotos/TNSI/TNSI (5).jpg";
import TNSI6 from "../../public/assets/images/awardphotos/TNSI/TNSI (6).png";
import TNSI7 from "../../public/assets/images/awardphotos/TNSI/TNSI (7).jpg";

import PRESS1 from "../../public/assets/images/awardphotos/press/press (1).JPG";
import PRESS2 from "../../public/assets/images/awardphotos/press/press (2).jpg";
import PRESS3 from "../../public/assets/images/awardphotos/press/press (3).jpg";
import PRESS4 from "../../public/assets/images/awardphotos/press/press (4).JPG";



import BEN1 from "../../public/assets/images/benchmarksphotos/BENCH2.png";
import BEN2 from "../../public/assets/images/benchmarksphotos/PRESS.jpg";
import BEN3 from "../../public/assets/images/benchmarksphotos/BEN3.jpg";
import BEN4 from "../../public/assets/images/benchmarksphotos/BEN4.JPG";
import BEN5 from "../../public/assets/images/benchmarksphotos/BEN5.jpg";
import BEN6 from "../../public/assets/images/benchmarksphotos/BEN6.jpg";
import BEN7 from "../../public/assets/images/benchmarksphotos/BEN7.jpg";



// import ImageSlider from "../components/slider/ImageSlider";
const ServiceList = [   
    {
        icon: <FiPieChart/>,
        title: 'Pillars of Unrivaled Excellence',
        description: 'Setting the Premium Standard is what we do at Codehunters. Our operational standards are the cornerstone of our commitment to surpassing industry norms and consistently delivering top-tier solutions.'
    },
    {
        icon: <FiTarget />,
        title: 'Elevating Quality',
        description: "A Legacy of Excellence. We've set the bar high, and we continually raise it, ensuring that our work stands as a testament to being the absolute best drivING innovation and ensuring impeccable quality "
    },
    {
        icon:  <FiAward/>,
        title: 'The Codehunters Way',
        description: 'At Codehunters , where Perfection is the Norm , our operational standards represent a relentless pursuit of excellence, ensuring that every project we undertake is executed flawlessly, making us the undisputed leaders at the forefront of technological advancement.'
    },
    {
        icon: <FiTrendingUp/>,
        title: 'Excellence Redefined',
        description: "We don't strive for good; we aim for greatness. Our commitment to excellence isn't just a motto; it's our way of life. Explore our meticulously detailed operational standards, where every aspect is crafted to not just meet, but exceed industry norms."
    },
    
]

const first3Items = ServiceList.slice(0,3);

const imgStyle = {height: '100%',objectFit: 'cover',transition: 'transform 0.3s',};

const borStyle={borderTop: '5px solid rgb(252,86,0)'};
const padStyle={paddingLeft : '10%',paddingRight : '10%'};
const cardTitle={color: '#ff5100', fontWeight: '900'};
const cardDesp={color: 'gray',paddingLeft: '10%',paddingRight: '10%'};



const buttonStyle = {
    
    backgroundColor: 'rgb(256, 81, 0)',
    borderColor: 'rgb(255, 0, 0)',
  };

  const vignetteOverlayStyles = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%', // Adjust the height of the vignette effect
    background: 'radial-gradient(ellipse at center, transparent 85%, rgba(256, 52, 0, 1) 10%)'
  };

const AwardsList1=[{
     
      logo : SIHLOGO,
      title: "SMART INDIA HACKATHON 2022",
      recognition: "WORLD CHAMPIONS",
      description: "The Smart India Hackathon 2022, a monumental event held in 75 locations with participants numbering in the lakhs, provided us with an opportunity to showcase our skills and innovation. Representing team CODESTRIX, we ventured into this journey with high hopes, facing challenges and overcoming obstacles along the way.The SIH2022 was more than just a competition; it was a testament to our resilience, teamwork, and unwavering determination. From facing adversity to emerging as winners, our journey taught us that with the right mindset and a dedicated team, the impossible becomes possible.",
      venue:"ACS College of Engineering ,Bangalore",
      product: 'One Time Handshake Multifactor Authentication (PS-NR1162)',
      tagId: [
        {
          image: SIHLOGO,
        },
        {
          image: SIH1,
          
        },
        {
          image: SIH2,
          
        },
        {
          image: SIH3,
        
        },
        {
          image: SIH7,
         
        },
        {
          image: SIH5,
        
        },
        {
          image: SIH4,
        
        },
        {
          image: SIH6,
          
        },]
     },

]
const AwardsList2=[
   {
      logo : TNSILOGO,
      title: "TAMIL NADU STUDENT INNOVATORS AWARD 2021",
      recognition: "STATE CHAMPIONS",
      description: " The Tamil Nadu Student Innovators Programme, initiated by EDII-TN, aims to inspire student innovators and entrepreneurs from across Tamil Nadu. The program consists of a series of events, including awareness and ideation sessions, boot camps, and a final pitch competition. A panel of judges from the Startup Ecosystem selects innovative ideas for recognition, awarding each recipient Rs. 1.00 lakh to further develop their prototype and bring their product to market. We are proud to announce that our product received the Best Innovative Award and emerged as the winner of the TNSI 2021-22 Season. We were honored to be recognized by the Tamil Nadu MSME Minister for our outstanding achievement.",
      venue:"Anna University , Guindy",
      product: 'Octus Women Safety Application',
      tag: [
        {
          image: TNSILOGO,
        },
        {
          image: TNSI1,
          
        },
        {
          image: TNSI2,
          
        },
        {
          image: TNSI3,
        
        },
        
        {
          image:TNSI4,
        
        },
        {
          image: TNSI5,
        
        },
        {
          image: TNSI6,
          
        },{
            image: TNSI7,
            
          },]
     },

]

const BenchmarksList=[{
    logo : BEN1,
    title: "UMAGINE 2023",
    subtitle: "Asia's Largest Tech Summit",
    description: "Special Invitee of UMAGINE Chennai,organised by the Govt. of Tamil Nadu and ELCOT which stands as Asia’s largest three-day summit on technology and innovation with more than 250 industry veterans and over 10,000 delegates, from March 23 to 25, 2023.",
    venue:"World Trade Center , Chennai",
    product: 'One Time Handshake Multifactor Authentication', },
    {
        logo : BEN2,
        title: "OCTUS PRODUCT SHOWCASE",
        subtitle: "STATE WIDE PRESS MEET",
        description: "An Official Press Meet to over 15+ Media Channels across Tamil Nadu for our Innovative Product OCTUS ,at the Project Day 2022, Which stands as the World's first Beacon themed security appication, with the moto to elevate the Safety and security of the Women of Our Country",
        venue:"SRM Campus , Trichy",
        product: 'One Time Handshake Multifactor Authentication', },
        {
            logo : BEN5,
            title: "PROUD ESPERANZA'2023 SPONSOR",
            subtitle: "GUEST SPEAKER",
            description: "From Being the Student Coordinator of Esperanza'2020 to being the Sponsor of the Entire Symposium Event , Codehunters Paved the Way for the Event with prominent Guest Lecture by pride representative of our company",
            venue:"Srm, Trichy",
            product: 'One Time Handshake Multifactor Authentication', },
            {
                logo : BEN3,
                title: "VISUALIZE THE FUTURE - Virtual Tech Talk",
                subtitle: "GUEST SPEAKERs",
                description: "Featured Guest Speakers with the session focussing on Hackathons, Interview Preparation, and Upcoming Tech, an opportunity to share our expertise and insights with the next generation of tech leaders. This moment underscores our commitment to mentorship and marks a significant benchmark in our journey as tech innovators. ",
                venue:"Bethlahem College of Engineering",
                product: 'Bethlahem College of Engineering', },
                {
                    logo : BEN4,
                    title: "Startup TN Brandlabs, 2022",
                    subtitle: "Official Logo Launch",
                    description: "Grand Logo Unveiling at Startup TN Brandlabs 2023: Our iconic logo was unveiled amid the presence of distinguished dignitaries, marking a momentous occasion in our journey. This event underscores our commitment to innovation and brand excellence.",
                    venue:"BIT Campus, Trichy",
                    product: 'Bethlahem College of Engineering', },
                    {
                        logo : BEN6,
                        title: "Startup TN , 2022",
                        subtitle: "Product Showcase",
                        description: "We proudly showcased our flagship product, OCTUS, at Startup TN 2022, an event hosting thousands of startups. This dynamic marketing and networking experience exemplified our commitment to innovation and collaboration within the thriving startup ecosystem.",
                        venue:"World Trade Center, Chennai",
                        product: 'World Trade Center, Chennai', }]

class WhatWeDid extends Component{

    
    render(){

        // const { imageWidth } = this.state;
        let title = 'What we did',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='What we did' />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'What we did'}
                
                bg='breadcrumb-area rn-bg-color ptb--150 bg-14' 
                headbelow_p = {"We started as students ready to pursue our dreams with Passion and unwavering determination, embracing the roller-coaster ride of Entrepreneurship. Now we stand tall as one of the Leading Startup's of Tamil Nadu"}    />
                {/* End Breadcrump Area */}

                


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3>Transforming Operational Standards</h3>
                                    <p className="mt-5">Elevating Excellence: Our operational standards are the foundation of our commitment to innovation, quality, and consistency. Dive into our rigorous processes that ensure every aspect of our work meets the highest industry benchmarks.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <a className="" href="/service-details"> */}
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}



              

  {/* Start Award & Recognitions */}
         <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3>Awards & Recognition</h3>
                                    <p className="mt-5">From winning the prestigious Tamil Nadu Student Innovation Challenge to conquering the Smart India Hackathon, our journey is etched with accolades .
                                    Discover the pinnacle of our achievements through our benchmarks, where innovation, dedication, and exceptional performance converge to define our unwavering commitment to excellence</p>
                                </div>
                            </div>
                        </div>
                  </div>

                  {AwardsList1.map( (vala , i) => (
                                <div className="container mt-4" key={i}>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12 mb-4">
                                    <div className="card border-2 shadow">
                                        
                                        <div className="row no-gutters">
                                        <div
                                            className="col-lg-12 "
                                            
                                        >
                                            <div className="card-body text-center mt-4 ">
                                            <h3
                                                className="card-title text-center text-orange "
                                                style={cardTitle}
                                            >
                                                {vala.title}
                                            </h3>
                                            <h6 className="card-subtitle mb-2 text-muted font-weight-bold">{vala.recognition}</h6>
                                            <div className="row mb-md-5 mb-sm-3">
                                                    <div className="col-lg-12 mb-sm-5">
                                                    <ImageSlider tag={vala.tagId}/> 
                                                    
                                                    {console.log(vala.tagId)}
                                                    </div>
                                            
                                             </div>
                                            <p
                                                className="card-text mt-4 text-center text-secondary text-justify "
                                                style={padStyle}
                                            >
                                               
                                                {vala.description}
                                                
                                            </p>
                                            <p className="card-text text-center mt-4 mb-0 text-secondary" >
                                                <strong className="text-dark">Product Name:</strong> {vala.product}
                                            </p>
                                            <p className="card-text text-center mt-0 mb-0 text-secondary">
                                                <strong className="text-dark">Felicitation Venue:</strong> {vala.venue}
                                            </p>
                                            </div>
                                         
                                        </div>
                                        </div>
                                       
                                        <div className="text-center mt-4">
                                            {/* <button
                                                type="button"
                                                className="btn btn-sm px-3 mr-1 btn-dark "
                                                
                                            >
                                                View Photos
                                            </button> */}
                                            <a href="/products">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-lg w-100 rn-bg-color font-weight-medium " style={buttonStyle}
                                                >
                                                  <b>VIEW PRODUCT</b>  
                                                </button>
                                                </a>
                                            </div>
                                         </div>
                                 
                                    </div>
                              


                                </div>
                           
                        
                             
                                

                        </div>
                    ))}

                    <br></br>
                    {AwardsList2.map( (valax , i) => (
                                <div className="container mt-4" key={i}>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12 mb-4">
                                    <div className="card border-2 shadow">
                                        
                                        <div className="row no-gutters">
                                        <div
                                            className="col-lg-12 "
                                            
                                        >
                                            <div className="card-body text-center mt-4 ">
                                            <h3
                                                className="card-title text-center text-orange "
                                                style={cardTitle}
                                            >
                                                {valax.title}
                                            </h3>
                                            <h6 className="card-subtitle mb-2 text-muted font-weight-bold">{valax.recognition}</h6>
                                            <div className="row mb-md-5 mb-sm-3">
                                                    <div className="col-lg-12 ">
                                                    {/* <ImageSlider tag={valax.tag}/>  */}
                                                    <br></br>
                                                    <img src={TNSI7} alt=""/>
                                                    {console.log(valax.tag)}
                                                    </div>
                                            
                                             </div>
                                            <p
                                                className="card-text mt-4 text-center text-secondary text-justify "
                                                style={padStyle}
                                            >
                                               
                                                {valax.description}
                                                
                                            </p>
                                            <p className="card-text text-center mt-4 mb-0 text-secondary" >
                                                <strong className="text-dark">Product Name:</strong> {valax.product}
                                            </p>
                                            <p className="card-text text-center mt-0 mb-0 text-secondary">
                                                <strong className="text-dark">Felicitation Venue:</strong> {valax.venue}
                                            </p>
                                            </div>
                                         
                                        </div>
                                        </div>
                                       
                                        <div className="text-center mt-4">
                                            {/* <button
                                                type="button"
                                                className="btn btn-sm px-3 mr-1 btn-dark "
                                                
                                            >
                                                View Photos
                                            </button> */}
                                             <a href="/products">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-lg w-100 rn-bg-color font-weight-medium " style={buttonStyle}
                                                >
                                                  <b>VIEW PRODUCT</b>  
                                                </button> </a>
                                            </div>
                                         </div>
                                 
                                    </div>
                              


                                </div>
                           
                        
                             
                                

                        </div>
                    ))}
                </div>
           
  {/* End Award & Recognitions */}


            

{/* Traction Start*/}


<div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3>Track Records</h3>
                                    <p className="mt-5">Discover our chronicle of successes, milestones, and moments of innovation. From groundbreaking projects to prestigious awards, our track record showcases our unwavering commitment to excellence. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <TractionLayout />
                        </div>
                    </div>
                </div>









{/* Traction End */}

<div className="about-area   bg_color--1">
                    <div className="about-wrapper">
                        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--25">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-center"><span>WANT TO GET IN TOUCH ?</span>
                                            <h2> We'd Love to Hear from You </h2><a className="rn-button-style--2" href="/contact"><span>Get in Touch
                                                    </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


    {/* Benchmarks */}
    <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h3>KEY HIGHLIGHTS</h3>
                                    <p className="mt-5">From winning the prestigious Tamil Nadu Student Innovation Challenge to conquering the Smart India Hackathon, our journey is etched with accolades .
                                    Discover the pinnacle of our </p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                   
                        <div className="container mt-4">
                                <div className="row">

                                {BenchmarksList.map( (vala , i) => (
                                    <div className="col-lg-12 col-md-12 mb-4" key={i}>
                                    <div className="card border-2 shadow">
                                        <div className="row no-gutters">
                                        <div className="col-lg-5 d-flex align-items-center justify-content-center">
                                            <img
                                            src={vala.logo}
                                            className="card-img img-fluid cover"
                                            style={imgStyle}
                                            alt="Award Images"
                                            />
                                            <div style={vignetteOverlayStyles}></div>
                                        </div>
                                        <div
                                            className="col-lg-7"
                                            
                                        >
                                            <div className="card-body text-center mt-4 ">
                                            <h5
                                                className="card-title text-orange"
                                                style={cardTitle}
                                            >
                                                {vala.title}
                                            </h5>
                                            <h6 className="card-subtitle mb-2 text-muted font-weight-bold display-sm-4"><b>{vala.subtitle}</b></h6>
                                            <p
                                                className="card-text mt-4 mb-4 text-center text-secondary text-justify "
                                                style={padStyle}
                                            >
                                               
                                                {vala.description}
                                                
                                            </p>
                                            {/* <p className="card-text mt-4 mb-0 text-secondary" >
                                                <strong className="text-dark">Product Name:</strong> {vala.product}
                                            </p> */}
                                            <p className="card-text mt-0 mb-4 text-secondary">
                                                <strong className="text-dark"> Event Venue:</strong> {vala.venue}
                                            </p>
                                            </div>
                                            {/* <div className="text-center mb-4">
                                            <button
                                                type="button"
                                                className="btn btn-sm px-3 mr-1 btn-dark mb-4"
                                                
                                            >
                                                View Photos
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary btn-sm px-3 mb-4" 
                                            >
                                                View Product
                                            </button>
                                            </div> */}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}


                                </div>
                                </div>
                           
                        </div>
                    </div>
                </div>
                {/* Benchmarks */}

                {/* Start work Area */}
                {/* <div className="rn-column-area rn-section-gap pt--90 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h2 className="tilte text-center">In what we are special</h2>
                                    <p className="text-center">Flexible and Reliable</p>
                                </div>
                                <div className="col-lg-12 col-12 order-1 order-lg-2">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src={SIH} alt="Service Images"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End work Area */}


                 

               

                {/* Start CounterUp Area */}
                {/* <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
                {/* End CounterUp Area */}

                

                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default WhatWeDid;