import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import OCTUS from "../../public/assets/images/productsphotos/octus.png";
import OTH from "../../public/assets/images/productsphotos/OTH.png";
import N2A from "../../public/assets/images/productsphotos/n2a.png";
import HASY from "../../public/assets/images/productsphotos/hasy.png";
import CRUIS from "../../public/assets/images/productsphotos/cruis.png";
import Whatsapp from "../components/common/Whatsapp";

const cardTitle={color:'#ff5100', fontWeight:'900'};
const padStyle={paddingLeft :'10%',paddingRight :'10%'};

const imgStyle = {height: '100%',objectFit: 'cover',transition: 'transform 0.3s',};
const buttonStyle = {
    
    backgroundColor: 'rgb(256, 81, 0)',
    borderColor: 'rgb(255, 0, 0)',
  };
  const vignetteOverlayStyles = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%', 
    background: 'radial-gradient(ellipse at center, transparent 85%, rgba(256, 52, 0, 1) 10%)'
  };
const ServiceList = [   
    {
        icon: <FiCast />,
        title: ' React Developers',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiLayers />,
        title: 'Flutter Developers',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    
]

const ProductsList=[{
    logo : OCTUS,
    title: "OCTUS Women Safety Application",
    recognition: "World's First Beacon themed safety App",
    description: "The Alert Trigger happens in less than 250 ms with capability to alert 6 million people in less than 30s. Functions with full efficiency even in the lack of internet .Various past Crime Scenarios can also be tracked down using OCTUS.Live Audio and Video access to trusted list members once SOS is triggeredModified T DES encryption Algorithm ensures no data breach happens",
    solution:"Building an advanced SOS Application that would help the women with alerting the surrounding people & police.Track live location spontaneously even without internet ,forming an all round shield.",
    problem: 'Crime cases and harassment  against women raising at an alarming rate.The women of India aren’t having proper improvised security provisions. Lack of immediate help leading to most untoward incidents',
    highlight: 'TAMIL NADU STUDENT INNOVATORS, 2021 - AWARD WINNING PRODUCT'
   },{
    logo : OTH,
    title: "One Time Handshake (OTH)",
    recognition: "Multi Factor Authentication System",
    description: "Highly secured Stand Alone System: OTH isn’t dependent on any external services and fully function by our pure  standalone Algorithm . Works even in Low Network Zone: OTH functions with same 100% efficiency even without internet . Cross Device Support- Works perfectly even for feature  phones , iOS as well as Android .Anti Breach System- Uncompromisable innovation",
    solution:"USSD Triggered Quad level security based Multifactor authentication system that works even in the absence of internet named as 'One Time Handshake !'",
    problem: "The Ministry of Defence's DRDO (Defence Research and Development Organization), under PS Code NS1162, seeking innovative solutions to address the challenge of OTP-based authentication in regions with poor mobile signal strength",
    highlight: 'SAMRT INDIA HACKATHON 2023 - AWARD WINNING PRODUCT'
},{
    logo : N2A,
    title: "Neural 2 Actions (N2A)",
    recognition: "Pioneer Digital Healthcare Equipment",
    description: "Realtime Patient Monitoring , Smartest & Effortless IoT Control ,Voice Control alongside Neural Intelligence , Every device can be made smart with our Custom IC Board controlled by our AI , Low cost IoT deployment eliminating need of new smart devices  AND Unpredictable mapping facilitating octa-layer protection ensures unbreakable security standards    ",
    solution:"Standalone AI System for Realtime Monitoring of every patient . Patient Disruptions will be tracked and alerted immediately. Turning the handicapped to hand-equipped through Neural Intelligence.  Thought / Voice based effortless control of every IoT device around",
    problem: 'Inability of hospitals to Monitor every Patient 24x7 , Critical Patients disruptions can’t be tracked immediately ,Differently abled people completely depend on abled persons AND Huge effort required even for the smallest of tasks',
    highlight: 'TAMIL NADU STUDENT INNOVATORS, 2019 - AWARD WINNING PRODUCT'
},
{
    logo : HASY,
    title: "HASY A.I.",
    recognition: "Advanced AI based Healthcare Platform",
    description: "Proficient Cloud Patient Monitoring system through ( HASY AI ) . Static Auto Report Generation of Patients visited and treated in the respective Health care centres .Patient Demographics, analytical and statistical report generation across all centres for the District healthcare officials .Unpredictable mapping facilitating octa-layer protection ensures unbreakable security standards",
    solution:"Interconnected advanced  AI based platform to ensure immediate health guidance to patients and help  health care centres  as well as district officials to support and monitor the  patients ",
    problem: 'Patients face problems in choosing the hospitals and specialized doctors for their treatment. No Platform to connect  health care centers ,patients and the district health officials for an efficient support and monitoring system',
    highlight: 'TECHATHON 2022 - AWARD WINNING PRODUCT'
},
{
    logo : CRUIS,
    title: "CRUIS Car A.I.",
    recognition: "World's First Beacon themed safety App",
    description: "‘From the seat’ Voice control of the entire functionalities of the car . Dynamic NLP Processing system with 56 different language Support . Monitor the entire state of the car in real time through cruis app powered by a New age Accident and Theft Alert System",
    solution:"AI-driven virtual voice assistant for vehicles to enhance road safety, monitor real-time car status through a mobile app, and provide timely alerts to family members in case of accidents or theft prevention",
    problem: 'Urgent need to enhance road safety by tackling distracted driving, lack of  real-time car monitoring through a medium, increasing post-accident fatality rates de to lack of immediate help  and increasing car theft. This builds up as a multifaceted challenge ',
    highlight: 'HONDA HACKATHON 2021 - WORLD TOP 25'
},

]

const first3Items = ServiceList.slice(0,3);


class Products extends Component{
    render(){
      
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Products' />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'CUtting Edge Innovations'}
                bg='breadcrumb-area rn-bg-color ptb--150 bg-11' 
                headbelow_p={'Step into a world of boundless innovation and unrivaled functionality. Immerse yourself in our transformative product ecosystem, where innovation meets functionality to shape industries and exceed functional expectations.'}  />
                {/* End Breadcrump Area */}

                


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Our Products</h2>
                                    <p>Discover Our Innovation Arsenal: Cutting-edge solutions designed to redefine industries. From pioneering apps to cybersecurity mastery, our products are the bridge between today's challenges and tomorrow's triumphs.</p>
                                </div>
                            </div>
                        </div>
                        {ProductsList.map( (vala , i) => (
                        <div className=" shadow mb-5">
                        <div className="mt-4">
                        
                        <div className="row   ">
                        
                                    <div className="col-lg-12 col-md-12  ">
                                    <div className="card">
                                        
                                        <div className="row no-gutters ">
                                        {/* <div className="col-lg-12 "> */}
                                            <div className="card-body text-center mt-4 ">
                                            <h3
                                                className="card-title text-center text-orange "
                                                style={cardTitle}
                                            >
                                                {vala.title}
                                            </h3>
                                            <h6 className="card-subtitle mb-2 text-muted font-weight-bold">{vala.recognition}</h6>
                                            <div className="row no-gutters d-flex align-items-center justify-content-center  mt-4 mb-md-5 mb-sm-3">
                                                    <div className="col-lg-5 d-flex align-items-center mr-lg-4 mt-md-4  mb-sm-4 shadow justify-content-center "> 
                                                                <img src={vala.logo}  style={imgStyle} alt=""/>
                                                                <div className="" style={vignetteOverlayStyles}></div>
                                                    </div>
                                                    <div className="col-lg-6 mt-4 px-4 align-items-center justify-content-center">
                                                        {/* <img src={vala.logo}  style={imgStyle} alt=""/>
                                                        <div style={vignetteOverlayStyles}></div>   */}
                                                          <p className="card-text text-left mb-2 text-secondary" >
                                                               <strong className="text-dark">PROBLEM STATEMENT :<br></br></strong> 
                                                               {vala.problem}
                                                          </p>
                                                          <p className="card-text text-left mt-0 mb-0 text-secondary">
                                                                <strong className="text-dark">SOLUTION:<br></br></strong> 
                                                                {vala.solution}
                                                           </p>
                                                  </div>
                                                     </div>
                                            
                                            


                                            <p
                                                className="card-text mt-4 text-center text-secondary text-justify "
                                                style={padStyle}
                                            >
                                               
                                                {vala.description}
                                                
                                            </p>
                                          
                                            </div>
                                         
                                        </div>
                                              
                                        <div className="text-center mt-4">
                                 
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-lg w-100 rn-bg-color font-weight-medium " style={buttonStyle}
                                                >
                                                  <b>{vala.highlight}</b>  
                                                </button>
                                            </div>
                                        </div>
                                 
                                         </div>
                                 
                                  </div>
                               
                         </div>
                        
                       
                        
                        </div>
                    
                    
                    // </div>
                    ))}
                    </div>
                </div>
                {/* End Service Area */}

                
                {/* Start About Area */}
                <div className="about-area   bg_color--1">
                    <div className="about-wrapper">
                        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--25">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-center"><span>WANT TO GET IN TOUCH ?</span>
                                            <h2> We'd Love to Hear from You </h2><a className="rn-button-style--2" href="/contact"><span>Get in Touch
                                                    </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                
                


                 

               

                {/* Start CounterUp Area */}
                {/* <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
                {/* End CounterUp Area */}

                

                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                {/* <Whatsapp/> */}
                <Footer />

            </React.Fragment>
        )
    }
}
export default Products;