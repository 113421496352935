import React, { Component } from "react";
import PortfolioList from "../elements/portfolio/PortfolioList";


class Portfolio extends Component{
    render(){
        const {title,description} = this.props;
        return(
            
            <React.Fragment>

                
                


                

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">{title}</h2>
                                        <p>{description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                           
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                
            </React.Fragment>
        )
    }

    
}


export default Portfolio;