import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Whatsapp from "./Whatsapp";
class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | CODEHUNTERS </title>
                    <meta name="description" 
                    content="CODEHUNTERS is a globally recognized Marketing agency & Web and Application development company with experts that help enterprises, businesses and startups with innovative solutions like Web Development | App Development | Digital Marketing | Graphic Design | Security Analysis" />
                </Helmet>
                <Whatsapp/>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
