import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContentWeb = [
    {
        image: 'image-1',
        category: 'Software',
        title: 'MEGA SOFTWARE SOLUTIONS',
        url:'https://megasolutionsbh.com/' 
    },
    {
        image: 'image-2',
        category: 'Ed-Publications',
        title: 'QUING PUBLICATIONS',
        url:'https://quingpublications.com/' 


    },
    {
        image: 'image-3',
        category: 'Trading Portfolio',
        title: 'WAHRHEIT INTERNATIONAL',
        url:'https://www.wahrheitinternational.com/' 

    },
    {
        image: 'image-5',
        category: 'Digital Screens',
        title: 'PRO-RGB',
        url:'https://pro-rgb.com/'
    },
    {
        image: 'image-6',
        category: 'Banking Sector',
        title: 'ALBARAKA BANK',
        url:'https://albaraka.com/en/' 

    },
    {
        image: 'image-4',
        category: 'Service',
        title: ' MOBILE CARE V2 ',
        url:'https://mobilecarev2.com/' 

    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
       
        
            const list = PortfolioListContentWeb.slice(0 , this.props.item);
            
    
        
        return(
            <React.Fragment> 
                
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.url}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.url}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;