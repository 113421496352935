import React from 'react'
import '../../public/assets/css/traction.css'
import { FaAccusoft, FaChartLine, FaGrinStars, FaSteam } from 'react-icons/fa'
import { FiFlag } from 'react-icons/fi'
export default function TractionLayout() {
  return (
    <div className="container">
  <div className="row ">
    <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
      <div className="row  mt-4">
        {/* <section class="step one "> */}
        <div
          className="col-lg-4 col-md-4 col-sm-4 mt-2 d-flex justify-content-center align-items-center"
          id="ch-traction"
        >
          <div className="circle1 align-items-center ">
            <i
              className="logo fa-solid fa-globe mb-3 "
              style={{ color: "#ff5100" }}
            ><FaAccusoft  size="3rem"/></i>
            <h4 className="content" style={{ color: "red" }}>
              <b>2020-21</b>
            </h4>
            <p className="des text-dark" >The Kickoff</p>
          </div>
        </div>
        <div
          className="col-lg-8 col-md-8 col-sm-8 align-items-center chdatabox"
          id="ch-traction"
        >
          <div className="row">
            <div className="col-lg-12">
              <header
                className="row align-items-center  mb-2 step1  "
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaAccusoft size="1.7rem"/></i>
                <p className="text-uppercase my-2  px-3  text-white text-bold text-sm ">
                  <b>TNSI 2019-20 Winning Startup </b>
                </p>
              </header>
              <header
                className="row align-items-center mb-2 step2"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                 <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaAccusoft size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold text-black text-sm">
                 <b>Best Innovation Award, NH</b> 
                </p>
              </header>
              <header
                className="row ml-8 align-items-center mb-2 step3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                  <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaAccusoft size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold text-black text-sm">
                <b>Officially AN MSME STARTUP</b>
                </p>
              </header>
              <header
                className="row align-items-center mb-2 step4"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                  <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaAccusoft size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold  text-black text-sm">
                <b>World Top 25 - Honda A.I.</b>
                </p>
              </header>
              <header
                className="row align-items-center mb-2 step5"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                  <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaAccusoft size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold text-black text-sm">
                <b>GDC Appreciation for CRUIS</b>
                </p>
              </header>
            </div>
          </div>
        </div>
        {/* </section> */}
      </div>
      <div className="row mt-5 ">
        {/* <section class="step one "> */}
        <div
          className="col-lg-4 col-md-4 col-sm-4 mt-2 d-flex justify-content-center align-items-center "
          id="ch-traction"
        >
          <div className="circle1 align-items-center ">
            <i
              className="logo fa-solid fa-gears mb-3 "
              style={{ color: "#ff5100" }}
            ><FaSteam size="3rem"/></i>
            <h4 className="content" style={{ color: "red" }}>
              <b>2021-22</b>
            </h4>
            <p className="des text-dark">Building</p>
          </div>
        </div>
        <div
          className="col-lg-8 col-md-8 col-sm-8 align-items-center chdatabox"
          id="ch-traction"
        >
          <div className="row">
            <div className="col-lg-12">
              <header className="row align-items-center  mb-2 step1">
                <i
                  className="fa-solid fa-star"
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                ><FaSteam size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3  text-dark text-sm">
                <b>TNSI 2021-22 Winning Startup </b>
                </p>
              </header>
              <header className="row align-items-center mb-2 step2">
              <i
                  className="fa-solid fa-star"
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                ><FaSteam size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3  text-dark text-sm">
                <b>Book OF innovations, TN  </b>
                </p>
              </header>
              <header className="row ml-8 align-items-center mb-2 step3">
              <i
                  className="fa-solid fa-star"
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                ><FaSteam size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3  text-dark text-sm">
                <b>SIH'2022 World Champions</b>
                </p>
              </header>
              <header className="row align-items-center mb-2 step4">
              <i
                  className="fa-solid fa-star"
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                ><FaSteam size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3  text-dark text-sm">
                <b>Knowledge Partner - PRAG</b>
                </p>
              </header>
              <header className="row align-items-center mb-2 step5">
              <i
                  className="fa-solid fa-star"
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                ><FaSteam size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-dark text-bold text-sm">
                <b>Trademark 50 Projects</b>
                </p>
              </header>
            </div>
          </div>
        </div>
        {/* </section> */}
      </div>
      <div className="row mt-5 ">
        {/* <section class="step one "> */}
        <div
          className="col-lg-4 col-md-4 col-sm-4 mt-2 d-flex justify-content-center align-items-center"
          id="ch-traction"
        >
          <div className="circle1 align-items-center ">
            <i
              className="logo fa-solid fa-line-chart mb-3 "
              style={{ color: "#ff5100" }}

            ><FaChartLine size="3rem"/></i>
            <h4 className="content" style={{ color: "red" }}>
              <b>2022-23</b>
            </h4>
            <p className="des text-dark">The Empire</p>
          </div>
        </div>
        <div
          className="col-lg-8 col-md-8 col-sm-8 align-items-center  chdatabox"
          id="ch-traction"
        >
          <div className="row">
            <div className="col-lg-12">
              <header
                className="row align-items-center  mb-2 step1  "
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaChartLine size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3  text-white text-bold text-sm ">
                <b>Client Base - 8 Countries</b>
                </p>
              </header>
              <header
                className="row align-items-center mb-2 step2"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                <i
                  className="fa-solid fa-star "
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaChartLine size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold text-black text-sm">
                <b>ABIRF Official Incubation</b>
                </p>
              </header>
              <header
                className="row ml-8 align-items-center mb-2 step3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                <i
                  className="fa-solid fa-star "
                  id=""
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaChartLine size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold text-black text-sm">
                <b>Techkathon'22 Winners,TN</b>
                </p>
              </header>
              <header
                className="row align-items-center mb-2 step4"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                <i
                  className="fa-solid fa-star"
                  id=""
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaChartLine size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold  text-black text-sm">
                <b>Pathway to Private Ltd. </b>
                </p>
              </header>
              <header
                className="row align-items-center mb-2 step5"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              >
                <i
                  className="fa-solid fa-star"
                  style={{ backgroundColor: "rgb(256, 82, 0, 1)" }}
                  ><FaChartLine size="1.7rem"/></i>
                <p className="text-uppercase my-2 ps-4 px-3 text-white text-bold text-black text-sm">
                <b>Grand Invitee of UMAGINE'23</b>
                </p>
              </header>
            </div>
          </div>
        </div>
        {/* </section> */}
      </div>
    </div>
  </div>
  {/* Add more award cards as needed */}
</div>

  )
}
