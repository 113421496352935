import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import FooterTwo from "../components/footer/FooterTwo";
import ServiceExpain from "../components/ServiceExplain";
import ContactForm from "../blocks/ContactForm";
import Portfolio from "../blocks/Portfolio";
import CallAction from "../components/HomeLayout/homeOne/callaction/CallAction";
import gd1 from '../../public/assets/images/ui/1.webp';
import gd2 from '../../public/assets/images/ui/2.webp';
import gd3 from '../../public/assets/images/ui/3.webp';
import gd4 from '../../public/assets/images/ui/4.webp';
import gd5 from '../../public/assets/images/ui/5.svg';






const ServiceList = [   
    {
        icon: <FiCast />,
        title: 'Branding',
        description: 'To create a launch-ready solution, we collaborate with you to cut through the clutter and keep focused on identifying key system features for Brands'
    },
    {
        icon: <FiLayers />,
        title: 'Motion Graphics',
        description: 'youre in need of an explainer video to showcase your product or services, or an animated logo to give your brand a fresh, dynamic look, our team of experts has the skills and experience to create a custom solution that will exceed your expectations. '
    },
    {
        icon: <FiUsers />,
        title: 'Arts and Illustration',
        description: 'Designs that will elevate your brand and make it stand out in the marketplace, from concept art to character designs. We can produce unique graphics that flawlessly capture your vision and effectively communicate your narrative mediums and styles.'
    },
    
]


const ExplainListGraphics = [
    {
        title : 'Discover & Devise',
        description : 'Target Audience, Market and the Problem',
        sub1 : 'Market Analysis',
        sub2 : 'Requirements',
        sub3 : 'Conceptualisation',
        des1 : 'Examine what has previously been accomplished by competing products to improve the experience.',
        des2 : 'Using thorough brainstorming sessions, pinpoint the essential requirements and the solutions vision.',
        des3 : 'Identify the main business issue that has to be resolved with the end user in mind.',
        img : gd1

    },
    {
        title : 'Define & Refine',
        description : 'Blueprint of the Core',
        sub1 : 'Wireframe',
        sub2 : 'User Journeys',
        sub3 : 'Mindmap',
        des1 : 'putting important modules and activities in a logical hierarchy when designing information architecture',
        des2 : 'Heuristic evaluation of user behaviour by starting with design features that people are most likely to expect',
        des3 : 'putting important modules and activities in a logical hierarchy when designing information architecture',
        img : gd2


    },
    {
        title : 'UI / UX Design',
        description : 'Interface, Experience & Visuals',
        sub1 : 'Interface',
        sub2 : 'Objective Design',
        sub3 : 'Digital Experience',
        des1 : 'As a top website design firm, we carefully build gorgeous pixels to produce an aesthetically pleasing interface of pertinent panels.',
        des2 : 'Through our mobile app design services, we produce solutions that adhere to fixing the problems root cause in addition to looking gorgeous',
        des3 : 'By carefully balancing interface, information, and interaction, our mobile app designing services provide an useful design.',
        img : gd3

    }

]




const first3Items = ServiceList.slice(0,3);


class GraphicDesign extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='GRAPHICS DESIGN ' />
                {/* <HeaderFive headertransparent="header--transparent"  colorblack="color--black" logoname="logo.png" /> */}

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                {/* Start Breadcrump Area */}
                <Breadcrumb title={'GRAPHICS DESIGN'} headbelow_p={'Fresh From The Press Discription'} bg='breadcrumb-area rn-bg-color ptb--150 bg-11'   />
                {/* End Breadcrump Area */}

                

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Graphics Design</h2>
                                    <p>Complex processes go into simple design. With our website design services, we streamline it by concentrating on Challenges and Solutions, then developing practical user interfaces that put the audience at the forefront.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <a className="text-center" href="/service-details"> */}
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Graphical</h2>
                                    
                                    <p>By recognising and adjusting to changes considerably earlier in the entire process—a stage where the cost of modifications is higher—we produce high-quality goods that stay within the predetermined budget.

</p>
                                    <img src={gd4}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <CallAction/>
                               
                {ExplainListGraphics.map( (val , i) => (
                <div className="about-area ptb--60  bg_color--1" key={i}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={val.img} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{val.title}</h2>
                                            <p className="description">{val.description}</p>
                                            <h4>{val.sub1}</h4>
                                            <p className="description">{val.des1}</p>
                                            <h4>{val.sub2}</h4>
                                            <p className="description">{val.des2}</p>
                                            <h4>{val.sub3}</h4>
                                            <p className="description">{val.des3}</p>


                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}


<div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Design is not how it looks<br></br>
                                    But how it works.</h2>
                                    
                                    <p>-Steve Jobs</p>
                                    <img src={gd5}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Portfolio/> */}
                
               <ContactForm/>
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default GraphicDesign;