import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import pr from "../../public/assets/images/pr.webp";


class Testimonial extends Component{
    
    render(){
        
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We have been working with CODEHUNTERS for the past year and have been extremely impressed with their level of expertise and professionalism.</p>
                                    </div>
                                    <div className="author-info mt--20">
                                        <h6><span>Somu Karupaiah </span> - CEO, Mega software solutions, Bahrain.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>CODEHUNTERS helped us significantly improve our online presence and reach our marketing goals. I highly recommend them to any business looking to succeed in the digital world.</p>
                                    </div>
                                    <div className="author-info mt--20">
                                        <h6><span>Manimegalai</span> - COO, PRORGB, Bahrain.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>As a small business, we were intimidated by the idea of launching a digital marketing campaign. CODEHUNTERS made the process easy and stress-free, and the results have been fantastic.</p>
                                    </div>
                                    <div className="author-info mt--20">
                                    <h6><span>Kabilan</span> - CEO, Mobile care v2, Karaikal</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                    <p>Our website traffic and sales have both increased significantly, and we couldn't be happier. Thank you HUNTERS!</p>
                                    </div>
                                    <div className="author-info mt--20">
                                    <h6><span>Edison</span> - Founder, Quing Publications, Pondichery</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                    <p>We have worked with many different IT companies over the years, but none have impressed us as much as CODEHUNTERS.</p>
                                    </div>
                                    <div className="author-info mt--20">
                                    <h6><span>saravanan</span> - CEO, SM Chennai</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                    <p> Their team is knowledgeable, responsive, and a pleasure to work with. They have helped us develop custom software solutions that have greatly improved our operations and efficiency.</p>
                                    </div>
                                    <div className="author-info mt--20">
                                    <h6><span>Uniez</span> - CEO, Wahrheit International, UAE</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                    <p>We approached CODEHUNTERS with a very specific and complex app development project, and they exceeded our expectations at every turn. </p>
                                    </div>
                                    <div className="author-info mt--20">
                                    <h6><span>Srinithi Umanathan</span> - CEO, Retailers, Trichy.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                    <p>I have been using CODEHUNTERS's YouTube videos to learn about various IT topics, and I have been extremely impressed with the quality of the content. </p>
                                    </div>
                                    <div className="author-info mt--20">
                                    <h6><span>Vimal</span> - CEO, JKM-MART, Jayankondam.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={pr} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;