import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import FooterTwo from "../components/footer/FooterTwo";
import ServiceExpain from "../components/ServiceExplain";
import ContactForm from "../blocks/ContactForm";

import CallAction from "../components/HomeLayout/homeOne/callaction/CallAction";
import PortfolioListTwo from "../elements/portfolio/PortfolioListTwo";
import a1 from '../../public/assets/images/app/an.webp';
import a2 from '../../public/assets/images/app/ap.webp';

import a3 from '../../public/assets/images/app/hy.webp';
import m from '../../public/assets/images/app/m-app.webp';





const ServiceList = [   
    
    {
        icon: <FiMonitor />,
        title: 'Enterprise',
        description: 'The mobile roadmap that is in line with enterprise scenarios, policies, and existing systems acts as a catalyst in the streamlining of IT frameworks and processes thanks to our collaborative consultation on mobile strategy.'
    },
    {
        icon: <FiCast />,
        title: 'Business',
        description: 'To help businesses react to changes fast, we outline their needs and use cases. We support your companys efforts to streamline departmental procedures, communication, and information accessibility.'
    },
    {
        icon: <FiMonitor />,
        title: 'Consumers',
        description: 'Our mobile expertise assist brands, companies, and community organisations in developing scalable mobile application solutions that are intended to be used frequently throughout the day by people throughout the world.'
    },
]





const ExplainListApp = [
    {
        title : 'Android',
        description : 'We are a top provider of custom Android mobile app solutions for large corporations, small businesses, and startups. We can assist you with creating specialised Android apps for smartphones, tablets, Android Wear, and Android TV, covering the complete gamut of the Android platform. Hire Android app developers from us who are skilled at creating unique Android apps that are compatible with the most recent Android OS versions and all Android devices.',
        sub : 'Android',
        l1 : 'Android Smartphone Apps',
        l2 : 'Android Tablet Apps',
        l3 : 'Firebase Integration',
        img : a1

    },
    {
        title : 'IOS',
        description : 'Top iOS/ iPhone app development firm CODEHUNTERS has a strong team of knowledgeable iOS developers at its disposal. We provide our clients with unique apps that operate flawlessly throughout the full iOS platform, including iOS devices like iPhones, iPads, Apple Watches, Apple TV, and more. Hire seasoned iOS app developers to create mobile applications with pleasant user experiences using the newest iOS app development technologies.',
        sub : 'IOS',
        l1 : 'iPhone App Development',
        l2 : 'iPad App Development',
        l3 : 'iOS App Testing',
        img : a2

    },
    {
        title : 'Cross-Platform',
        description : 'Top cross-platform mobile app development company CODEHUNTERS equips organisations with a variety of mobile apps. Our cross-platform mobile apps give clients numerous advantages and assist in reaching a larger audience using devices running various OSes. With cross-platform technologies and frameworks like Flutter, React Native, PhoneGap, and Cordova, our cross-platform developers have demonstrated competence in creating cost-effective, high-quality, and versatile mobile apps.',
        sub : 'Cross-Platform',
        l1 : 'Hybrid Mobile App Development',
        l2 : 'Migration to Hybrid Frameworks',
        l3 : 'Flutter App Development',
        img : a3

    }

]


class MobileApp extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Mobile App Development' />
                {/* <HeaderFive headertransparent="header--transparent"  colorblack="color--black" logoname="logo.png" /> */}

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Mobile App Development'}  bg='breadcrumb-area rn-bg-color ptb--150 bg-7' headbelow_p={'With a team of experienced developers and a track record of delivering top-quality apps, CODEHUNTERS is the premier choice for all your application development needs.'}  />
                {/* End Breadcrump Area */}

                

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Application Development</h2>
                                    <p>we pride ourselves on our ability to deliver innovative and effective app solutions for businesses of all sizes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>We think mobile makes business</h2>
                                    
                                    <p>We deliver rich experiences on small screens thanks to our extraordinarily in-depth knowledge of user experience and mobile technology, which directly affects your company's success, operational simplicity, and consumer engagement. In order to maximise the influence on your end users, we collaborate with various ecosystems.</p>
                                    <img src={m}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <CallAction/>

                               
                {/* <ServiceExpain/> */}

                {ExplainListApp.map( (val , i) => (
                <div className="about-area ptb--60  bg_color--1" key={i}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={val.img} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{val.title}</h2>
                                            <p className="description">{val.description}</p>
                                            <h4 className="title">{val.sub}</h4>
                                            <ul>
                                                <li>
                                                    {val.l1}
                                                </li>
                                                <li>
                                                    {val.l2}
                                                </li>
                                                <li>
                                                    {val.l3}
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}

                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">App Works</h2>
                                        <p>Our Mobile application development products for our clinets business to grow</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                            <PortfolioListTwo styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />

                            </div>
                        </div>
                     </div>
                </div>
                
                
               <ContactForm/>
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default MobileApp;