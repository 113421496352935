import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiHeadphones, FiCode, FiLock, FiCompass } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import ab from "../../public/assets/images/ab.webp";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaAddressCard, FaBuffer, FaDesktop, FaServer, FaTimesCircle, FaWineGlass } from "react-icons/fa";

const ServiceList = [   
    {
        icon: <FiCast />,
        title: 'Evolving Scope',
        description: 'Very clear scope and stable set of requirements that isn’t likely to change throughout the project.Can define clear long term milestones – irrespective of the working models chosen (i.e. Agile or Waterfall)'
    },
    {
        icon: <FiLayers />,
        title: 'Constrained Budget',
        description: 'The Scope is defined very briefly and the requirements are very likely to change throughout the project. Can define clear short term milestones – depending on the working model chosen (i.e. Agile or Waterfall)'
    },
    
]

const ExpertiseList=[
    {
        icon: <FiCode/>,
        title: 'Innovative Tech Integration',
        category: 'Innovation ',
        description : ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam modi dolorem ullam deserunt, quam delectus voluptate voluptatum maiores accusantium blanditiis, non neque eos natus consequuntur porro laborum nostrum placeat. Reiciendis.',
        data: ''
    
      },
    {
        icon: <FaServer/>,
        title: 'Industry Specific Solutions ',
        category: 'Mastery',
        description : ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam modi dolorem ullam deserunt, quam delectus voluptate voluptatum maiores accusantium blanditiis, non neque eos natus consequuntur porro laborum nostrum placeat. Reiciendis.'
    },
    {
        icon: <FiLock/>,
        title: 'Security & Compliance Excellence',
        category: 'Precision',
        description : ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam modi dolorem ullam deserunt, quam delectus voluptate voluptatum maiores accusantium blanditiis, non neque eos natus consequuntur porro laborum nostrum placeat. Reiciendis.'
    },
    {
        icon: <FaAddressCard/>,
        title: 'Data Driven Decision Making',
        category: 'Diversity',
        description : ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam modi dolorem ullam deserunt, quam delectus voluptate voluptatum maiores accusantium blanditiis, non neque eos natus consequuntur porro laborum nostrum placeat. Reiciendis.'
    },
    {
        icon: <FiCompass/>,
        title: 'Agile Project Management',
        category: 'Excellence',
        description : ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam modi dolorem ullam deserunt, quam delectus voluptate voluptatum maiores accusantium blanditiis, non neque eos natus consequuntur porro laborum nostrum placeat. Reiciendis.'
    },
    {
        icon: <FaDesktop/>,
        title: 'User Centric Design ',
        category: 'Versatility',
        description : ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam modi dolorem ullam deserunt, quam delectus voluptate voluptatum maiores accusantium blanditiis, non neque eos natus consequuntur porro laborum nostrum placeat. Reiciendis.'
    }


]

const first3Items = ServiceList.slice(0,3);
const liStyle = {borderTop: '5px solid rgb(252,86,0)' , fontSize:'23px',paddingTop: '20px'}

class HowWeWork extends Component{
    render(){
        
        return (
          <React.Fragment>
            <PageHelmet pageTitle="How we work" />

            <HeaderFive
              headerPosition="header--static logoresize"
              logo="all-dark"
              color="color-black"
            />

            {/* Start Breadcrump Area */}
            <Breadcrumb
              title={"STREAMLINED PROCESS"}
              bg="breadcrumb-area rn-bg-color ptb--150 bg-19"
              headbelow_p={
                "Crafting Success through Collaboration: We blend visionary strategy with relentless teamwork, transforming ideas into extraordinary outcomes. At Codehunters, innovation is our compass, excellence our destination, and partnership our driving force."
              }
            />
            {/* End Breadcrump Area */}

            {/* Start Service Area */}
            <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                      <h2>How We Engage?</h2>
                      <p>
                        Every project is a unique project, and hence – requires
                        very special and dedicated treatment. Besides the
                        critical triangle of cost, time and scope – the working
                        model between the client and the agency also plays a
                        crucial part in balancing the former. That’s why, we
                        have a very robust, yet simple models – that are
                        extremely flexible and client-centric inside.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row creative-service">
                  {ServiceList.map((val, i) => (
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                      key={i}
                    >
                      {/* <a className="" href="/service-details"> */}
                      <div className="service service__style--2">
                        <div className="icon">{val.icon}</div>
                        <div className="content">
                          <h3 className="title">{val.title}</h3>
                          <p>{val.description}</p>
                        </div>
                      </div>
                      {/* </a> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* End Service Area */}


            {/* Start How we work timeline */}
            <div className="container">
            <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                      <h2>OUR EXPERTISE</h2>
                      <p>
                      At Codehunters, our mastery transcends boundaries. From cutting-edge development to cybersecurity fortresses, our diverse skill set powers innovation. We're your trusted partner on the journey to tech excellence.
                      </p>
                    </div>
                  </div>
                </div>
                <br/>
                <VerticalTimeline>
                    {/* {console.log(i)} */}
                
                 {ExpertiseList.map((val1, i) => (
                            <VerticalTimelineElement key={i} 
                            className="vertical-timeline-element--work"
                            date={val1.data}
                            contentStyle={{ background: '', color: 'bg_color--1' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(252,86,0)' }}
                            iconStyle={{
                              background: "rgb(252,86,0)",
                              color: "#fff",
                            }}
                            shadowSize={'large'}
                            icon={val1.icon}
                           
                          >
                            <h3 className="vertical-timeline-element-title"  style={ liStyle }>
                              {val1.title}
                            </h3>
                            <h4 className="vertical-timeline-element-subtitle">
                              {val1.category}
                            </h4>
                            <p>
                             {/* {val1.description} */}
                            </p>
                            {/* {console.log(i+1)} */}
                          </VerticalTimelineElement>
                  ))}
                    {/* <VerticalTimelineElement
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<FaBuffer />}
                    /> */} 
                     {/* {console.log(i)} */}
                </VerticalTimeline>
             
            </div>
            {/* End How we work timeline */}

            {/* Start work Area */}
            <div className="rn-column-area rn-section-gap pt--90 pb--120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-column">
                      <h2 className="tilte text-center">Our Workflow</h2>
                      <p className="text-center">Flexible and Reliable</p>
                    </div>
                    <div className="col-lg-12 col-12 order-1 order-lg-2">
                      <div className="thumb position-relative">
                        <img className="w-100" src={ab} alt="Service Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End work Area */}
            <div className="about-area   bg_color--1">
                    <div className="about-wrapper">
                        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--25">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-center"><span>WANT TO GET IN TOUCH ?</span>
                                            <h2> We'd Love to Hear from You </h2><a className="rn-button-style--2" href="/contact"><span>Get in Touch
                                                    </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* Start CounterUp Area */}
            {/* <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
            {/* End CounterUp Area */}

            {/* Start Brand Area */}
            <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <BrandTwo />
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </React.Fragment>
        );
    }
}
export default HowWeWork;