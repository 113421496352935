import React from 'react'
const CallAction = () => {
    return (
        <div className='callaction '>
        <div className="call-to-action-wrapper call-to-action text-white-wrapper">
            <div className="container ptb--60">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <br></br>
                            <span>WANT TO GET IN TOUCH ?!</span>
                            <h2>We'd Love To Hear From You</h2>
                            <a className="rn-button-style--2 mb-10" href="/contact"><span>GET IN TOUCH</span></a>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span>WANT TO LEARN ?</span>
                            <h2>EXPLAIN & EXPLORE</h2>
                            <a className="rn-button-style--2" href="/contact"><span>LEARN</span></a>
                        </div>
                    </div>
             </div>
            </div>
        </div> */}
    </div>
    )
}
export default CallAction;