import React from 'react'

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import ContactThree from "../elements/contact/ContactThree";

const ContactForm = () => {
    return (
        <>
            
            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Contact Form  */}
                <div className="rn-contact-form-area ptb--120 bg_color--1">
                    <ContactThree contactTitle="Contact Us" contactImages="/assets/images/about/about-6.jpg" />
                </div>
                {/* Start Contact Form  */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            
            {/* End Footer Area  */}

        </>
        
    )
}

export default ContactForm;