import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Software Development Company',
        title: 'MEGA SOFTWARE SOLUTIONS',
        source:'https://megasolutionsbh.com/'
    },
    {
        image: 'image-2',
        category: 'Academic Publisher',
        title: ' QUING PUBLICATIONS',
        source:'https://quingpublications.com/'
    },
    {
        image: 'image-3',
        category: 'Share Market',
        title: 'WAHRHEIT INTERNATIONAL',
        source:'https://www.wahrheitinternational.com/'
    },
    {
        image: 'image-4',
        category: 'Mobile Servicing',
        title: ' MOBILE CARE V2',
        source:'https://mobilecarev2.com/'
    },
    {
        image: 'image-1',
        category: 'Digital Screens',
        title: 'PRO-RGB',
        source:'https://pro-rgb.com/'
    },
    {
        image: 'image-6',
        category: 'Banking Sector',
        title: ' ALBARAKA BANK',
        source:'https://albaraka.com/'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'OUR WORKS',
        description = 'we are proud of the work we have done for our clients. Here are just a few examples of the projects we have completed.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="section-title text-center mt--50 ">
                                    <h2>{title}</h2>
                                    <p>we are proud of the work we have done for our clients. Here are just a few <br/> examples of the projects we have completed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><a target='_blank' href={value.source}>{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" target='_blank' href={value.source}>View Site</a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;