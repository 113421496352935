import React from 'react'
import AOS from "aos";
import '../../../public/assets/css/aos.css'
import { FaWhatsapp } from 'react-icons/fa';
AOS.init();

export default function Whatsapp() {
  return (
        <div className="container text-center">
            <a  href="https://wa.me/6379857110"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer" >
            <i className="fa fa-whatsapp whatsapp-icon"> <FaWhatsapp color='white' /></i>
            </a>
        </div>
  )
}
