import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';


const ServiceExplainList = [
    {
        image: 'https://test.codehunters.co.in/assets/images/service/cms.png',
        description1: 'Hey all',
        title: 'Business Stratagy',
        description2: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        image: 'https://test.codehunters.co.in/assets/images/service/cms.png',
        description1: 'Hey all',
        title: 'Business Stratagy',
        description2: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        image: 'https://test.codehunters.co.in/assets/images/service/cms.png',
        description1: 'Hey all',
        title: 'Business Stratagy',
        description2: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
]




let title = 'About',
        description = 'There are many  of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';



class ServiceExplain extends Component{
    render(){
        return(
            <React.Fragment>
                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="https://test.codehunters.co.in/assets/images/service/cms.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="https://test.codehunters.co.in/assets/images/service/cms.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="https://test.codehunters.co.in/assets/images/service/cms.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="about-area ptb--60  bg_color--1">
                    <div className="about-wrapper">
                        

                        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--25">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-center"><span>WANT TO GET IN TOUCH ?</span>
                                            <h2> We'd Love to Hear from You </h2><a className="rn-button-style--2" href="contact.php"><span>Get in Touch
                                                    </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            {/* <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="https://test.codehunters.co.in/assets/images/service/cms.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> */}
                        
                    </div>
                </div>
                {/* End About Area */}
                

                {/* Start Service Area */}
                
                {/* End Service Area */}

                {/* Start Service Area */}
                
                {/* End Service Area */}

                {/* Start Service Area */}
                
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
              


            


            </React.Fragment>
        )
    }
}
export default ServiceExplain;