import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'As a reputable software development and digital marketing organisation, we provide a wide range of web development, app development, digital marketing, graphic design, and security analysis services to help businesses stand out from the competition.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>We provide a wide range of services, such as software development, web  development, and IT consulting, as a top IT firm situated in Jayankondam.</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">What we do</h3>
                                                <p>Our team has a wide range of abilities, and we are constantly searching for fresh, creative approaches to assist our clients in realising their objectives.</p>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;