import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
// import Header from "../component/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import Testimonial from "../elements/Testimonial";





const ServiceList = [   
    {
        icon: <FiCast />,
        title: ' React Developers',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiLayers />,
        title: 'Flutter Developers',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    
]

const first3Items = ServiceList.slice(0,3);


class Career extends Component{
    render(){
        let title = 'Career',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Career' />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Become a Part of CODEHUNTERS Family'}
                bg='breadcrumb-area rn-bg-color ptb--150 bg-15' 
                headbelow_p={'This is the place where you do not just get a Job, but a lot more that enables you to create and contribute something. If youre champion at what you do, and are hungry to learn, grow and make an impact, youve passed a level already. Were always eager to grow our team with folks who are innovative thinkers and passionate about producing results.'}  />
                {/* End Breadcrump Area */}

                


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>We offer careers, not jobs</h2>
                                    <p>To evolve with innovations, be adaptive and creating solutions through technology and strategy is what blends into transpiring pieces of work we do together. 
                                        There are plenty of areas to collaborate on - irrespective of your demography. We're always keen on working with inclusion, freedom, and responsibility.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" key={i}>
                                    <a className="" href="/contact">
                                        <div className="service service__style--2 w-100">
                                            {/* <div className="icon">
                                                {val.icon}
                                            </div> */}
                                            <div className="content">
                                                <h3 className="title text-center">{val.title}</h3>
                                                {/* <p>{val.description}</p> */}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                
                {/* Start About Area */}
                <div className="about-area   bg_color--1">
                    <div className="about-wrapper">
                        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--25">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-center"><span>WANT TO GET IN TOUCH ?</span>
                                            <h2> We'd Love to Hear from You </h2><a className="rn-button-style--2" href="/contact"><span>Get in Touch
                                                    </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                <div className="section-title text-center mb--20 mb_sm--0 mb_md--0">
                                    <h1 className="fontWeight900">TESTIMONIALS</h1>
                        </div>
                        <div className="container">
                            <Testimonial />
                        </div>
                </div>
                {/* End Testimonial Area */}

                


                 

               

                {/* Start CounterUp Area */}
                {/* <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
                {/* End CounterUp Area */}

                

                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Career;