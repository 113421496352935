// import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import { FiX , FiMenu } from "react-icons/fi";


// class HeaderFive extends Component{
//     constructor(props) {
//         super(props);
//         this.menuTrigger = this.menuTrigger.bind(this);
//         this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
//        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
//         window.addEventListener('load', function() {
//             console.log('All assets are loaded')
//         })
//     }
//     menuTrigger() {
//         document.querySelector('.header-wrapper').classList.toggle('menu-open')
//     }
//     CLoseMenuTrigger() {
//         document.querySelector('.header-wrapper').classList.remove('menu-open');
//     }
//     render(){
//         var elements = document.querySelectorAll('.has-droupdown > a');
//         for(var i in elements) {
//             if(elements.hasOwnProperty(i)) {
//                 elements[i].onclick = function() {
//                     this.parentElement.querySelector('.submenu').classList.toggle("active");
//                     this.classList.toggle("open");
//                 }
//             }
//         }
        
//         const { logo, color , headerPosition } = this.props;
//         let logoUrl;
//         if(logo === 'light'){
//             logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
//         }else if(logo === 'dark'){
//             logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
//         }else if(logo === 'symbol-dark'){
//             logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
//         }else if(logo === 'all-dark'){
//             logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
//         } else if(logo === 'symbol-light'){
//             logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
//         }else{
//             logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
//         }
        
//         return(
//             <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
//                 <div className="header-wrapper" id="header-wrapper">
//                     <div className="container">
//                         <div className="row align-items-center">
//                             <div className="col-lg-3 col-md-4 col-6">
//                                 <div className="header-left">
//                                     <div className="logo">
//                                         <a href="/">
//                                             {logoUrl}
//                                         </a>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-9 col-md-8 col-6">
//                                 <div className="header-right justify-content-end">
//                                     <nav className="mainmenunav d-lg-block">
//                                         <ul className="mainmenu">
//                                             <li className="has-droupdown"><Link to="/">Home</Link></li>
//                                             {/* <li className="has-droupdown">Service
//                                                 <ul className="submenu">
//                                                 <li><Link to="/web-development">Web Development</Link></li>
//                                                 <li><Link to="/Mobile-App-Development">Mobile App Development</Link></li>
//                                                 <li><Link to="/Digital-Marketing">Digital Marketing</Link></li>
//                                                 <li><Link to="/graphic-design">Graphic Designing</Link></li>
//                                                 <li><Link to="/education">Education</Link></li>
                                                    
//                                                 </ul>
//                                             </li> */}

//                                             <li className="has-droupdown">About
//                                             <ul className="submenu">
//                                             <li><Link to="/team">Team</Link></li>
//                                             <li><Link to="/how-we-work">How We Work</Link></li>
//                                             <li><Link to="/what-we-did">What We Did</Link></li>
//                                             <li><Link to="/career">Career</Link></li>
                                                
//                                                 </ul>
//                                             </li>

//                                             <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
                                            
//                                         </ul>
//                                     </nav>
                                    
//                                     {/* Start Humberger Menu  */}
//                                     <div className="humberger-menu d-block d-lg-none pl--20">
//                                         <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
//                                     </div>
//                                     {/* End Humberger Menu  */}
//                                     <div className="close-menu d-block d-lg-none">
//                                         <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         )
//     }
// }
// export default HeaderFive;



import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import Whatsapp from "../common/Whatsapp";


class HeaderFive extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { logo, color , headerPosition } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Trydo" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Trydo" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Trydo" />;
        }else if(logo === 'all-dark'){
            logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
        } else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Trydo" />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Trydo" />;
        }
        
        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-lg-4 col-md-5 col-9">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-3">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        
                                        <ul className="mainmenu">
                                            <li><Link to="/">Home</Link>
                                                
                                            </li>
                                            <li className="has-droupdown"><Link to='#'  >Service</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/web-development">Web development</Link></li>
                                                    <li><Link to="/mobile-app-development">App development</Link></li>
                                                    <li><Link to="/digital-marketing">Digital marketing</Link></li>
                                                    <li><Link to="/graphic-design">Graphic design</Link></li>
                                                    <li><Link to="/education">Education</Link></li>


                                                    
                                                </ul>
                                            </li>
                                            
                                            <li className="has-droupdown"><Link to='#'  >About</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/team">Team</Link></li>
                                                    <li><Link to="/how-we-work">How we work</Link></li>
                                                    <li><Link to="/what-we-did">What we did</Link></li>
                                                    <li><Link to="/career">Career</Link></li>
                                                    <li><Link to="/products">Products</Link></li>
                                                    
                                                </ul>
                                            </li>

                                            
                                            
                                            <li><Link to="/contact" >Contact</Link></li>
                                            
                                            
                                        </ul>
                                        
                                    </nav>
                                    <div className="header-btn row">
                                        <a className="rn-btn" href="tel:+919095241578">
                                            <span>Call now</span> 
                                        </a>
                                    </div>
                                    
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderFive;