import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import {FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/FooterTwo";








class Er extends Component{
    render(){
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='404' />

                <HeaderFive headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
               

                <main className="page-wrapper">
            
            <div className="error-page-inner bg_color--4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">404!</h1>
                                <h3 className="sub-title">Page not found</h3><span>The page you were looking for could not
                                    be found.</span>
                                <div className="error-button">
                                    <a className="rn-button-style--2 btn_solid" href="/">Back To Homepage</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </main>
                

                

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Er;