import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";

class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--35" data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <br></br>
                                <h1 className="title theme-gradient">CODEHUNTERS</h1>
                            </div>
                        </div>
                    </div>
                    {/* Start Service Area -->ServiceOne*/}
                    <div className="service-wrapper ">
                        <div className=" col-lg-8">
                          <p className="">Codehunters is a globally recognized Web Development Company and Application Development company with Graded Developers that help enterprises, businesses or even startups with innovative solutions like
                          Web Development | App Development | Digital Marketing | Graphic Design | Security Analysis</p>
                      </div>
                    </div>
                    
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;